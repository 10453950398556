/* source-sans-pro-300 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/source-sans-pro-v13-latin-300.eot"); /* IE9 Compat Modes */
  src:
    local("Source Sans Pro Light"),
    local("SourceSansPro-Light"),
    url("./fonts/source-sans-pro-v13-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/source-sans-pro-v13-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/source-sans-pro-v13-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/source-sans-pro-v13-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/source-sans-pro-v13-latin-300.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}

/* source-sans-pro-300italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  src: url("./fonts/source-sans-pro-v13-latin-300italic.eot"); /* IE9 Compat Modes */
  src:
    local("Source Sans Pro Light Italic"),
    local("SourceSansPro-LightItalic"),
    url("./fonts/source-sans-pro-v13-latin-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/source-sans-pro-v13-latin-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/source-sans-pro-v13-latin-300italic.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/source-sans-pro-v13-latin-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/source-sans-pro-v13-latin-300italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/source-sans-pro-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    local("Source Sans Pro Regular"),
    local("SourceSansPro-Regular"),
    url("./fonts/source-sans-pro-v13-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/source-sans-pro-v13-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/source-sans-pro-v13-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/source-sans-pro-v13-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/source-sans-pro-v13-latin-regular.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}

/* source-sans-pro-italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/source-sans-pro-v13-latin-italic.eot"); /* IE9 Compat Modes */
  src:
    local("Source Sans Pro Italic"),
    local("SourceSansPro-Italic"),
    url("./fonts/source-sans-pro-v13-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/source-sans-pro-v13-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/source-sans-pro-v13-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/source-sans-pro-v13-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/source-sans-pro-v13-latin-italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}

/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/source-sans-pro-v13-latin-600.eot"); /* IE9 Compat Modes */
  src:
    local("Source Sans Pro SemiBold"),
    local("SourceSansPro-SemiBold"),
    url("./fonts/source-sans-pro-v13-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/source-sans-pro-v13-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/source-sans-pro-v13-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/source-sans-pro-v13-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/source-sans-pro-v13-latin-600.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/source-sans-pro-v13-latin-700.eot"); /* IE9 Compat Modes */
  src:
    local("Source Sans Pro Bold"),
    local("SourceSansPro-Bold"),
    url("./fonts/source-sans-pro-v13-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/source-sans-pro-v13-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/source-sans-pro-v13-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/source-sans-pro-v13-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/source-sans-pro-v13-latin-700.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}

/* source-sans-pro-700italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/source-sans-pro-v13-latin-700italic.eot"); /* IE9 Compat Modes */
  src:
    local("Source Sans Pro Bold Italic"),
    local("SourceSansPro-BoldItalic"),
    url("./fonts/source-sans-pro-v13-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/source-sans-pro-v13-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/source-sans-pro-v13-latin-700italic.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/source-sans-pro-v13-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/source-sans-pro-v13-latin-700italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
