body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}

a {
  color: rgb(81, 131, 193);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.tippy-box {
  background: transparent !important;
}

.tippy-content {
  padding: 0px !important;
  margin: 0px !important;
}

.tippy-box[data-placement^="top"] > .tippy-arrow::before {
  border-top-color: grey !important;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow::before {
  border-bottom-color: grey !important;
}
.tippy-box[data-placement^="left"] > .tippy-arrow::before {
  border-left-color: grey !important;
}
.tippy-box[data-placement^="right"] > .tippy-arrow::before {
  border-right-color: grey !important;
}
